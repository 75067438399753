const initalMappings = {
    "afrobeat": ["afro"],
    "alternative": ["rock", "indie"],
    "ambient": ["ambient"],
    "anime": ["anime"],
    "big": ["big band"],
    "band": ["big band"],
    "bebop": ["jazz"],
    "bluegrass": ["bluegrass"],
    "blues": ["blues", "funk"],
    "bossanova": ["bossanova"],
    "brazil": ["latin"],
    "breakbeat": ["electronic"],
    "chicago house": ["electronic"],
    "chill": ["chill", "hiphop"],
    "chillhop": ["hiphop"],
    "classical": ["classical"],
    "club": ["electronic"],
    "comedy": ["comedy"],
    "cool": ["jazz"],
    "country": ["country", "guitar"],
    "dance": ["electronic"],
    "dancehall": ["electronic"],
    "death-metal": ["metal"],
    "deep house": ["electronic"],
    "detroit-techno": ["electronic"],
    "disco": ["electronic"],
    "disney": ["happy", "pop"],
    "drum and bass": ["drum and bass", "electronic"],
    "dub": ["electronic", "reggae"],
    "dubstep": ["electronic"],
    "edm": ["electronic"],
    "electro": ["electronic"],
    "electronic": ["electronic"],
    "emo": ["metal", "rock"],
    "folk": ["folk"],
    "forro": ["latin"],
    "funk": ["funk", "soul"],
    "garage": ["electronic"],
    "gospel": ["soul", "funk"],
    "goth": ["metal"],
    "grindcore": ["metal"],
    "grunge": ["rock", "metal"],
    "guitar": ["guitar"],
    "hard rock": ["rock", "metal"],
    "hardcore": ["metal"],
    "hardstyle": ["electronic"],
    "heavy-metal": ["metal"],
    "hip-hop": ["hiphop"],
    "hiphop": ["hiphop"],
    "hip hop": ["hiphop"],
    "hip": ["hiphop"],
    "hop": ["hiphop"],
    "holidays": ["jazz", "happy"],
    "honky-tonk": ["country", "rock"],
    "house": ["electronic"],
    "indian": ["indian"],
    "indie": ["indie", "modern"],
    "indie-pop": ["indie", "pop"],
    "industrial": ["avante-gard", "rock"],
    "j-dance": ["japanese"],
    "j-idol": ["japanese"],
    "j-pop": ["japanese"],
    "j-rock": ["japanese", "rock"],
    "jazz": ["jazz"],
    "k-pop": ["pop", "electronic"],
    "kids": ["pop", "happy"],
    "latin": ["afro", "latin"],
    "latino": ["latin"],
    "metal": ["metal"],
    "metal-misc": ["metal"],
    "metalcore": ["metal"],
    "minimal-techno": ["electronic"],
    "new-age": ["chill", "ambient", "electronic"],
    "opera": ["vocal", "jazz", "jazzal"],
    "party": ["electronic"],
    "piano": ["piano"],
    "pop": ["pop"],
    "post-dubstep": ["electronic", "chill"],
    "post-grunge": ["indie"],
    "power-pop": ["rock", "pop"],
    "progressive-house": ["electronic", "ambient"],
    "psych-rock": ["rock", "avante-gard", "metal"],
    "punk": ["rock", "metal"],
    "punk-rock": ["rock"],
    "r&b": ["rnb", "soul", "funk"],
    "rnb": ["rnb", "soul"],
    "rainy-day": ["chill"],
    "rap": ["rap", "hiphop"],
    "reggae": ["reggae"],
    "reggaeton": ["latin", "electronic"],
    "rock": ["rock"],
    "rock-n-roll": ["rock", "jazz"],
    "rockabilly": ["rock", "jazz"],
    "romance": ["smooth", "jazz", "saxophone"],
    "salsa": ["latin"],
    "samba": ["latin"],
    "saxophone": ["saxophone"],
    "ska": ["reggae"],
    "sleep": ["chill", "piano"],
    "soul": ["soul"],
    "spanish": ["spanish"],
    "study": ["chill", "hip-hop"],
    "synth-pop": ["electronic", "pop"],
    "trap": ["hiphop"],
    "tango": ["latin"],
    "techno": ["electronic"],
    "trance": ["electronic", "chill"],
    "trumpet": ["trumpet"],
    "trip-hop": ["chill", "hiphop"],
    "world-music": ["world"]
}

export default initalMappings;